import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../component/layout';
import Seo from '../component/seo';
import PolicyPage from '../sections/Policy';

export default function Policy() {
  const {
    strapiMetaPage: { header, description },
    strapiPolicy: {
      header: titleHeader,
      text: {
        data: { text },
      },
    },
  } = useStaticQuery(
    graphql`
      query Policy {
        strapiMetaPage(page_name: { eq: "policy" }) {
          header
          description
          page_name
        }
        strapiPolicy {
          text {
            data {
              text
            }
          }
          header
        }
      }
    `,
  );

  return (
    <Layout titleHeader={titleHeader} activePath="/policy">
      <Seo title={header} description={description} />
      <PolicyPage text={text} />
    </Layout>
  );
}
