import * as React from 'react';
import PropTypes from 'prop-types';
import PolicyPage from '../../component/Policy';

export default function Policy({ text }) {
  return <PolicyPage text={text} />;
}

Policy.propTypes = {
  text: PropTypes.string.isRequired,
};
