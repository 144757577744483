import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '../Container';
import { Text } from './styled';

export default function Policy({ text }) {
  return (
    <Container>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
}

Policy.propTypes = {
  text: PropTypes.string.isRequired,
};
