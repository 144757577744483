import styled from 'styled-components';

export const Text = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  padding: 40px 0;
  font-size: 20px;
  line-height: 1.55;

  @media screen and (max-width: 1200px) {
    font-size: 18px;
  }
  @media (max-width: 578px) {
    font-size: 14px;
  }
`;
